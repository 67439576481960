import { UserManagerSettings } from 'oidc-client';

export const environment = {
  production: true,
  env: 'dev',
  hmr: false,
  registryUrl: 'https://registry.dev.browsermodules.ihsmarkit.com',
  apiBaseUrl: 'https://api.dev.polaris.ihsmarkit.com',
  wsUrl: 'wss://gateway.staging.sockets.ipreo.com/connect',
  enableRouterTracing: false,
  instrumentStore: true,
  loadLocalDevModules: false,
  oidcSettings: {
    authority:
      'https://sam.samexternal.net/sso/oauth2/realms/root/realms/Customers',
    client_id: 'GlobalMarkets-Polaris-Dev-Kd9zEGsc9Z',
    redirect_uri: 'https://dev.globalmarkets.ihsmarkit.com',
    post_logout_redirect_uri: 'https://dev.globalmarkets.ihsmarkit.com',
    response_type: 'code',
    scope: 'openid email profile',
    automaticSilentRenew: true,
    silentRequestTimeout: 10000,
    filterProtocolClaims: true,
    loadUserInfo: true
  } as UserManagerSettings
};

import 'zone.js/dist/zone-error'; // Included with Angular CLI.
